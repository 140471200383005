import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

const ProfileImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ProfileImage = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 100px;
`;

export default function About() {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "avatar" }) {
        id
        publicURL
      }
    }
  `);
  console.log(data);
  return (
    <Layout>
      <h1>Who am I?</h1>
      <ProfileImageWrapper>
        <ProfileImage src={data.file.publicURL} alt="Profile picture" />
      </ProfileImageWrapper>
      <p>
        Hi, my name's Dominik, a 23-year-old CS student and software engineer
        from Košice, Slovakia{' '}
        <span aria-label="Slovak flag" role="img">
          🇸🇰
        </span>
        .
      </p>
      <p>
        My primary focus is on web development. I like working with library
        React.js. Mostly using HTML5, CSS3 and JavaScript. I prefer Node.js with
        Express as a backend.
      </p>
      <p>
        Since 2019 I'm also a member of the{' '}
        <a target="_blank" rel="noreferrer" href="http://hackkosice.com/">
          Hack Kosice
        </a>{' '}
        organizers team, where I'm in a Hacker Service team. My responsibility
        is to make sure that hackers would feel comfortable and happy at our
        events.
      </p>
      <p>
        I'm also really into hackathons, I've attended some in the last couple
        of years. Even though we didn't win anything, I met dozens of innovative
        people who share the same joy from attending these events as me. To
        check out the projects I've worked on, you can visit my{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://devpost.com/domosino44"
        >
          Devpost profile
        </a>
        .
      </p>
      <p>
        Away from programming and IT in general, I like taking pictures only
        using my phone. I tend to post some of my favorite pictures on
        Instagram. If you are interested in these photos, go to my{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/dmatis810/"
        >
          Instagram
        </a>
        .
      </p>
      <Helmet>
        <title>About me</title>
      </Helmet>
    </Layout>
  );
}
